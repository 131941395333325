<template>
  <ion-page>
    <ContentContainer>
      <ion-grid class="container">
        <ion-row>
          <ion-col>
            <ion-text class="text-2xl font-bold ">
              <h1 class="mb-5">
                {{ $t('orderconfirmation') }}
              </h1>
            </ion-text>

            <ion-text v-html="thanksMessage" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ContentContainer>
  </ion-page>
</template>

<style scoped>

/deep/ a {
    color: var(--ion-color-moto);
}

/deep/ p {
    margin-bottom: 1em;
}
</style>

<script>
  import { APP_DEFAULTS } from "@/configs";
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";

  export default {
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER}),
      thanksMessage() {
        if (!this.user || !this.user.email_verified_at) {
           return this.$t('thanksforpurchasemessage_unverified', {
             contactmail: `<a href="mailto:${APP_DEFAULTS.CONTACT_EMAIL}">${APP_DEFAULTS.CONTACT_EMAIL}</a>`,
             contactphone: `<a href="tel:${APP_DEFAULTS.CONTACT_PHONE}">${APP_DEFAULTS.CONTACT_PHONE}</a>`,
           });
        }

        return this.$t('thanksforpurchasemessage', {
          contactmail: `<a href="mailto:${APP_DEFAULTS.CONTACT_EMAIL}">${APP_DEFAULTS.CONTACT_EMAIL}</a>`,
          contactphone: `<a href="tel:${APP_DEFAULTS.CONTACT_PHONE}">${APP_DEFAULTS.CONTACT_PHONE}</a>`,
        });
      }
    }
  }
</script>